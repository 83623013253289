import React from "react";
import path from "path";
import moment from "moment";
import { ToneAudioBuffer } from "tone";

import audioMetadata from "./audioMetadata.json";
import "./App.css";

const countLoadedBufs = (audios) =>
  audios.reduce((acc, curr) => acc + (curr.buffer.loaded ? 1 : 0), 0);

const AUDIO_QUALITY = {
  VBR_256: "VBR_256",
  CBR_320: "CBR_320"
};

const audioFolderForQuality = {
  [AUDIO_QUALITY.VBR_256]: "Audio",
  [AUDIO_QUALITY.CBR_320]: "Audio_320"
};

const getAudioUrl = ({ publicPath }, quality) => `${process.env.PUBLIC_URL}/${audioFolderForQuality[quality]}/${publicPath}`;

const getAudioQualityFromHashString = () => {
  const hashString = window.location.hash.slice(1);
  for (const quality in AUDIO_QUALITY) {
    if (AUDIO_QUALITY[quality] === hashString) {
      return AUDIO_QUALITY[quality];
    }
  }
  // Returns default if unable to parse from hash
  return AUDIO_QUALITY.VBR_256;
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      audioData: {},
      loadStartTime: null,
      loadEndTime: null,
      audioQuality: null
    };
  }
  componentDidMount() {
    console.log("Loading...");


    const audioQuality = getAudioQualityFromHashString();
    this.setState({
      loadStartTime: moment(),
      audioQuality
    });
    const newAudioData = {};

    for (const a of audioMetadata) {
      const { publicPath } = a;
      const url = getAudioUrl(a, audioQuality);
      const name = path.basename(publicPath);
      const buffer = new ToneAudioBuffer(url, () => {
        this.setState({
          audioData: {
            ...this.state.audioData,
          },
        });
      });
      const audioData = {
        ...a,
        name,
        buffer,
      };
      newAudioData[name] = audioData;
    }
    this.setState({
      audioData: newAudioData,
    });

    ToneAudioBuffer.loaded().then(() => {
      this.setState({
        loadEndTime: moment(),
      });
    });
  }
  render() {
    const { audioData, loadStartTime, loadEndTime, audioQuality } = this.state;

    const numLoaded = countLoadedBufs(Object.values(audioData));

    const audioByPerformance = Object.values(audioData).reduce((acc, curr) => {
      const { performanceGroup } = curr;

      if (!(performanceGroup in acc)) {
        acc[performanceGroup] = [];
      }

      acc[performanceGroup].push(curr);
      return acc;
    }, {});

    const numLoadedByPerformance = Object.keys(audioByPerformance).map(
      (performanceGroup) => {
        const audiosForPerformance = audioByPerformance[performanceGroup];
        return {
          performanceGroup,
          numLoaded: countLoadedBufs(audiosForPerformance),
        };
      }
    );

    let totalLoadTime = null;
    if (loadStartTime && loadEndTime) {
      totalLoadTime = loadEndTime.diff(loadStartTime, "seconds");
    }

    return (
      <div className="App">
        <p>Audio quality: {audioQuality} </p>
        <p>
          Loaded {numLoaded} of {audioMetadata.length}
        </p>
        <div style={{ textAlign: "left" }}>
          Performances loaded:
          <br />
          {numLoadedByPerformance.map(({ performanceGroup, numLoaded }) => (
            <p key={performanceGroup}>
              {performanceGroup}: {numLoaded} of{" "}
              {audioByPerformance[performanceGroup].length}
            </p>
          ))}
        </div>
        {totalLoadTime ? (
          <div>Total loading time: {totalLoadTime} seconds</div>
        ) : null}
      </div>
    );
  }
}

export default App;
